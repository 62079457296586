import { useContext } from 'react';
import Head from 'next/head';
import styled from 'styled-components';
import RestaurantContext from '../Context/RestaurantContext';
import { Container } from 'react-bootstrap';
import LoginFormComponent from '../components/MemberArea/LoginFormComponent';
import { useRouter } from 'next/router';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Row, Col } from 'react-bootstrap';
import Icon from '../components/Icon';

const IlluWrapper = styled.div`
  margin-left: -15px;
  width: 100%;
`;

function LoginPage(props) {
  const { restaurantProps: storeProps } = useContext(RestaurantContext);
  const {
    query: { redirectUrl },
  } = useRouter();

  const title = `${storeProps.restaurantName} - Einloggen zum Memberbereich`;
  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>

      <Container>
        <Row>
          <Col className="d-none d-lg-flex" lg={6} style={{ alignItems: 'end' }}>
            <IlluWrapper>
              <Icon name="illu-burger" />
            </IlluWrapper>
          </Col>
          <Col xs={12} lg={6}>
            <GoogleOAuthProvider clientId="723913101097-enpmnfbivabvvlq84dmdq4q0pnerdqab.apps.googleusercontent.com">
              <LoginFormComponent redirectUrl={redirectUrl} />
            </GoogleOAuthProvider>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default LoginPage;
